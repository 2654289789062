.app-container {
  margin-top: 1em;
  padding-bottom: 70px;
  padding-left: 2rem;
  padding-right: 2rem;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.fullscreen-container {
  min-height: 100vh;
  padding-top: 2em;
  align-items: center;
  display: flex;
  &__grey {
    background-color: $bt-brand-faint-alt;
  }
}

.container-mw-700 {
  max-width: 700px;
}
