@import 'new/variables-new';
//Fonts
$font-family: 'OpenSans', sans-serif;
$font-family-header: 'OpenSans', sans-serif;

$dark-bg-0: #232533;
$dark-bg-2: #1a1c26;
$dark-bg-5: #1a1c26;
$dark-bg-10: #252836;
$dark-bg-15: #323242;
$dark-bg-20: #3a3d4a;
$dark-bg-50: #b3b3b3;
$dark-highlight: #424b72;
$dark-highlight2: $dark-bg-20;
$dark-highlight3: #f18e7f;

$dark-highlight-color: #e1e1e1;
$dark-color: #747484;

$button-bg-dark: $dark-bg-0;
$button-bg-dark-hover: darken($button-bg-dark, 1);

// COLORS

$bt-brand-primary: #7b51fb;
$bt-brand-primary-faint: #1d0d4d;
//$bt-brand-primary-faint: #003100;
$bt-brand-primary-light: lighten($bt-brand-primary, 5);

$bt-brand-primary-dark: #1e0d26;
$bt-brand-primary-dark-alt: #17103a;
$bt-brand-primary-dark-desaturated: #202040;
$bt-brand-primary-dark-desaturated-light: #38386d;
$bt-brand-primary-dark-desaturated-lighter: #4e51be;
$bt-brand-primary-dark-desaturated-lighter-dark: #131216;

$bt-brand-faint: #f8f8f7;
$bt-brand-faint-alt: #f5f1ed;
$bt-brand-faint-alt-darken: #dfd8cc;
$bt-brand-faint-alt-dark: #1b1924;
$bt-brand-faint-hover: #f8f2f8;
$bt-brand-faint-hover-dark: #242230;

$bt-brand-4: #f6d46e;
$bt-brand-tertiary: #f18e7f;
$bt-brand-tertiary-dark: $dark-highlight3;
$bt-brand-secondary: #00a696;
$bt-brand-secondary-faint: #2d9189;
$bt-brand-secondary-faint-dark: #12776d;
$bt-brand-secondary-light: lighten($bt-brand-secondary, 40);
$bt-brand-secondary-hover: darken($bt-brand-secondary, 5);
$bt-brand-secondary-active: $bt-brand-secondary-hover;

$bt-brand-red: #f2576e;
$bt-brand-yellow: #fdfddd;

//Body
$body-text-faint: #717186;
$body-text-faint-dark: darken(#e3e3e3, 10);
$header-color: #1e0d26; // for headers and labels
$header-color-dark: #d2d2d2; // for headers and labels
$bt-link-color: $bt-brand-primary-dark; // for headers and labels
$bt-link-color-dark: $dark-highlight-color; // for headers and labels
$bt-link-color-hover: lighten($header-color, 10); // for headers and labels

//Navbar
$nav-link-color: $header-color; // navbar link
$nav-link-color-dark: darken($header-color-dark, 10); // navbar link
$nav-link-color-hover: $bt-brand-primary; //
$nav-link-color-active: $bt-brand-primary-dark;
$nav-link-color-hover-dark: $bt-brand-primary-dark-desaturated-light; //

$nav-link-color-active-dark: $bt-brand-primary-dark-desaturated-light;
$navbar-height: 5rem;
$navbar-background: transparent;
$nav-icon-color: $bt-brand-primary;
$nav-icon-color-dark: $dark-highlight;

$bt-input-border: #d9d9e9;
$bt-input-border-dark: $input-bg-dark;
$bt-input-border-active: $input-bg-dark;

$body-bg: #fff;


$butter-bar-bg: #fdf1dd;
$butter-bar-bg-dark: $dark-bg-10;

$bt-gradient-text: $body-text;
$bt-gradient-start: $bt-brand-faint-alt;
$bt-gradient-end: #fff;

//Panel
$panel-heading-height: 50px;
$panel-bg: white;
$panel-bg-dark: $dark-bg-10;
$panel-bg-darker: $dark-bg-5;
$panel-heading-bg: $bt-brand-primary-dark;
$panel-heading-bg-dark: $dark-bg-10;
$panel-heading-color: white;
$panel-heading-color-dark: $header-color-dark;
$panel-heading-border: 1px solid $bt-brand-primary-dark;
$panel-heading-border-dark: 1px solid transparent;

$bt-code-background: $dark-bg-0;
$bt-code-background-dark: $dark-bg-0;

//
$panel-icon-color: $bt-brand-primary-dark;
$panel-icon-border-color: #fff;
$panel-icon-background-color: #fff;

$panel-icon-border-color-dark: $dark-bg-20;
$panel-icon-background-color-dark: $dark-bg-20;
$panel-icon-color-dark: $dark-bg-50;

// Code help e.g. installing the SDK
$btn-code-button-background-hover: darken($bt-brand-faint-alt, 2);
$btn-code-button-background: $bt-brand-faint-alt;
$btn-code-button-background-dark-hover: lighten($panel-heading-bg-dark, 2);
$btn-code-button-background-dark: $panel-heading-bg-dark;
$btn-code-button-border: $bt-brand-faint-alt;
$btn-code-button-color: $header-color;
$btn-code-button-color-dark: $dark-highlight-color;
$btn-code-button-color-alt: $bt-brand-tertiary;
$btn-code-button-color-alt-dark: $dark-color;
$btn-color: white;
$btn-color-dark: #d9d9d9;

//Nav

$pagination-color: #333;

//BUTTONS
$btn-height: 44px;
$btn-secondary-bg: rgb(240, 240, 240);
$btn-default-color: white;
$hover-bg: rgb(240, 240, 240);
$hover-color: inherits;

//Panel Dark
$panel-alt-heading-bg: #fff;
$panel-alt-heading-color: #fff;

$tab-active-color: $header-color;
$tab-active-color-dark: $header-color-dark;

//SCAFFOLDING
$mobile-portrait: 320px;
$mobile-landscape: 480px;
$tablet-portrait: 768px;
$tablet-landscape: 1024px;

//GRID
$input-padding-x: 0.75rem !default;
$input-padding-y: 1rem !default;

$border-radius-default: 8px;
//TRANSITIONS
$button-transition: background-color 400ms cubic-bezier(0.23, 1, 0.32, 1);
//Hero
$hero-bg: white;


$bold: 400;

$card-default: 0 2px 13px 0 rgba(34, 53, 74, 0.1);
$card-default-dark: 0 6px 13px 0 rgba(0, 0, 0, 0.1);

$aside-base-background: $bt-brand-faint-alt;
$aside-base-background-dark: $dark-bg-0;
$aside-logo: $bt-brand-primary-dark-desaturated-lighter;
// active project and environment
$aside-base-highlight: $bt-brand-primary-dark-desaturated-lighter;
$aside-base-highlight-alt: $bt-brand-faint-alt-darken;
$aside-base-highlight-alt-color: white;
$aside-base-highlight-dark: $bt-brand-primary-dark-desaturated-lighter;
$aside-base-highlight-color: white;
$aside-base-highlight-color-dark: white;
// active project and environment
$aside-base-link: $bt-brand-faint-alt;
$aside-base-link-dark: $dark-bg-10;
$aside-base-link-color: $body-text-faint;
$aside-base-link-color-dark: $body-text-faint-dark;
// Environment link
$aside-nav-color-dark:$body-text-dark;
$aside-nav-inactive-color:transparentize($header-color,0.0);
$aside-nav-inactive-color-dark:transparentize($body-text-dark, 0.25);
$aside-nav-inactive-hover-color:transparentize($header-color, 0.25);
$aside-nav-inactive-hover-color-dark:transparentize($header-color-dark, 0.25);
// Background of other links e.g. acreate environment/organisation
$aside-link-alt-color: $bt-brand-secondary-faint;
$aside-link-alt-color-dark: $dark-highlight-color;
$aside-add-color: white;
$aside-add-color-dark: white;


// Shadows
$aside-box-shadow: 0 6px 13px 0
  transparentize($bt-brand-primary-dark-desaturated-light, 0.75); // Box shadow right menu
$aside-box-shadow-dark: 0 6px 16px 0 rgba(0, 0, 0, 0.25); // Box shadow right menu
$aside-project-link-active-box-shadow: none;

// Backgrounds
$aside-left-bg:$bt-brand-primary-dark-alt; // main aside menu
$aside-left-bg-dark:$dark-bg-5; // main aside menu
$aside-left-color:white; // main aside menu
$aside-left-color-dark:white; // main aside menu
$aside-left-color-active:white; // main aside menu
$aside-left-color-active-dark:white; // main aside menu

// project link
$aside-project-link-color: $aside-base-link-color;
$aside-project-link-color-dark: $aside-base-link-color-dark;
$aside-project-link: $aside-base-link;
$aside-project-link-dark: $aside-base-link-dark;
// project link active
$aside-project-link-active: $aside-base-highlight;
$aside-project-link-active-dark: $aside-base-highlight-dark;
$aside-project-link-color-active: $aside-base-highlight-color;
$aside-project-link-color-active-dark: $aside-base-highlight-color-dark;

// Active environment link
$aside-nav-collapse-border:$aside-base-highlight-alt;
$aside-nav-collapse-border-dark:$aside-base-highlight-dark;



