.dark {
  .panel {
    input {
      background-color: $input-bg-dark;
      color: $input-color-dark;
    }
    &--grey {
      background: $panel-grey-background-dark;
      .panel {
        background-color: $panel-bg-darker;
        .panel-content {
          background-color: $panel-bg-darker;
        }
      }
    }
  }
}
.dark .panel--flat {
  border: 1px solid #252836;
}
.panel {
  &--flat {
    box-shadow: none !important;
  }
  &--draggable {
    cursor: move;
    @include transition(background 200ms ease-in-out);

    .panel-content {
      @include transition(background 200ms ease-in-out);
    }
  }

  input[type='text'] {
    opacity: 1;
    border-radius: $border-radius;
  }

  &--grey {
    background: $panel-grey-background;
    border-radius: $border-radius-lg;

    padding: 20px;
  }
  &--transparent {
    border: none;
    background-color: transparent;
    box-shadow: none;
    .panel-heading {
      background-color: transparent;
    }
  }
  &--nested {
    box-shadow: none !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  &--no-shadow {
    box-shadow: none;
  }

  &__segments-achievements {
    border: 1px solid $bt-brand-primary !important;
  }
}
.dark {
  .panel {
    &--transparent {
      input[type='text'] {
        background: $input-bg-dark;
      }
    }
  }
}

.header {
  &--icon {
    .btn--with-icon {
      position: relative;
      top: -4px;
      margin-left: 5px;
    }
  }
}

.plan {
  border-radius: $border-radius;
  border: 1px solid $basic-alpha-16;
  &-icon {
    padding: 12px;
    background-color: $primary-alfa-8;
    border-radius: $border-radius-lg;
    margin-right: 12px;
  }
}

/*Change text in autofill textbox*/
.dark {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: $body-text-dark;
    -webkit-box-shadow: 0 0 0px 1000px $input-bg-dark inset;
    transition: background-color 5000s ease-in-out 0s;
  }
  .plan {
    background-color: $bg-dark300;
    path {
      fill: $text-icon-light;
    }
  }
}
