.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.544);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    .title{
        color: #fff
    }
}