.legal-container {
	background:#f9f9f9;
	padding: 100px 0;
}

footer.legalpage{
	background: $hero-bg;
	color:white;
	padding:50px 50px 50px 250px;


	.brand-footer{
	}
}

.sla-list {
	padding-left: 0;
	list-style-position: inside;
	counter-reset: sla-counter;
	h5 {
		display: inline;
	}
	li {
		counter-increment: sla-counter;
		&::before {
			font-size: 2em;
		}
	}
}