.question-tooltip {
  .__react_component_tooltip {
    font-family: $font-family-header;
    font-size: $font-caption-sm;
    line-height: $line-height-xxsm;
    text-transform: none;
    padding: 5px 8px;
    border-radius: $border-radius-sm;
    opacity: 1;
    i {
      font-style: normal;
      color: $bt-brand-primary;
    }
    &.type-dark {
      background-color: $text-icon-dark;
      &.place-top {
        &:after {
          border-top-color: $text-icon-dark;
          border-right-width: 6px;
          border-left-width: 6px;
          margin-left: -6px;
        }
      }
      &.place-bottom {
        &:after {
          border-bottom-color: $text-icon-dark;
          border-right-width: 6px;
          border-left-width: 6px;
          margin-left: -6px;
        }
      }
      &.place-right {
        &:after {
          border-right-color: $text-icon-dark;
          border-top-width: 6px;
          border-bottom-width: 6px;
          margin-top: -5px;
        }
      }
      &.place-left {
        &:after {
          border-left-color: $text-icon-dark;
          border-top-width: 6px;
          border-bottom-width: 6px;
          margin-top: -5px;
        }
      }
    }
  }
}
.dark {
  .question-tooltip {
    .__react_component_tooltip {
      &.type-dark {
        background-color: $bg-light100;
        color: $text-icon-dark;
      }
    }
  }
}

.recharts-tooltip {
  background-color: $text-icon-light;
  border-radius: $border-radius-lg;
}

.yAxis {
  font-size: $font-caption-sm;
  line-height: $line-height-xxsm;
  font-weight: 500;
}
.xAxis {
  font-size: $font-caption-xs;
  line-height: $line-height-xxsm;
  font-weight: 500;
}

.dark {
  .recharts-tooltip-header {
    color: $text-icon-dark;
  }
  .xAxis {
    color: $text-icon-light-grey;
  }
  .recharts-wrapper {
    line {
      stroke: $bg-dark100;
    }
  }
}
