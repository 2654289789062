@mixin customScroll {
  /* width */
  &::-webkit-scrollbar {
    height: 12px;
    margin: 12px;
    width: 12px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background-color: $bt-scrollbar-track;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-clip: content-box;
    border: 3px solid transparent;
    background-color: $bt-scrollbar-thumb;
    border-radius: $border-radius;
    &:hover {
      background-color: $bt-scrollbar-thumb-hover;
    }
  }
}
@mixin customScrollDark {
  /* width */
  &::-webkit-scrollbar {
    height: 12px;
    margin: 12px;
    width: 12px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background-color: $bt-scrollbar-track-dark;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-clip: content-box;
    border: 3px solid transparent;
    background-color: $bt-scrollbar-thumb-dark;
    border-radius: $border-radius;
    &:hover {
      background-color: $bt-scrollbar-thumb-dark-hover;
    }
  }
}
