@import '../mixins/custom-scrollbar';
$animation-duration: 350ms;
$animation-timing-function: ease-in-out;

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }
  @-o-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}

@include keyframes(asideIn) {
  0% {
    left: -$aside-width;
  }
  100% {
    left: 0;
  }
}

@include keyframes(navAsideIn) {
  0% {
    left: 0;
  }
  100% {
    left: $aside-width;
  }
}

.dark {
  .aside {
    box-shadow: $aside-box-shadow-dark;
    background-color: $aside-left-bg-dark;

    &__add-btn {
      path {
        fill: $aside-add-color-dark;
      }
    }

    .btn-project {
      &:hover {
        color: white;
      }
      background: $aside-project-link-dark !important;
      color: $aside-project-link-color-dark !important;
      &.active {
        color: $aside-project-link-color-active-dark !important;
        background: $aside-project-link-active-dark !important;
        box-shadow: $aside-project-link-active-box-shadow;
      }
    }

    .collapsible {
      &__header {
        color: $aside-nav-color-dark;
        svg {
          path {
            fill: $aside-nav-color-dark;
          }
        }
      }

      &.active {
        .collapsible__header {
          color: $primary400;
        }
        &__header {
          color: $aside-nav-color-dark;
          svg {
            path {
              fill: $aside-nav-color-dark;
            }
          }
        }
      }
      .project-select {
        background-color: $bg-dark400;
      }
    }

    &__projects-item {
      &-title {
        color: $aside-left-color-dark;
        &.active {
          color: $aside-left-color-active-dark;
        }
      }
    }

    &__main-content {
      @include customScrollDark();
      background-color: $aside-bg-dark;
    }
    &__nav-item {
      color: $aside-nav-color;
      &.disabled {
        &.active,
        &.active:hover {
          color: $aside-nav-color-dark;
        }
      }
      path {
        fill: $aside-nav-color;
      }
      &.active,
      &.active:hover {
        background-color: $aside-nav-active-dark;
        color: $aside-nav-active-color-dark;
        .aside__nav-item--icon {
          path {
            fill: $aside-nav-active-color-dark !important;
          }
        }
      }

      &:hover {
        color: $aside-nav-hover-dark;
        path {
          color: $aside-nav-hover-dark;
        }
      }
    }
    .aside__header-link {
      &.active,
      &.active:hover {
        .aside__title-wrapper {
          background-color: $aside-nav-active-dark;
          color: $aside-nav-active-color-dark;
          path {
            fill: $aside-nav-active-color-dark;
          }
        }
      }
    }

    &__link-text {
      color: $success;
    }

    &__project-title {
      color: $aside-nav-color-dark;
    }

    .collapsible.active {
      background-color: $aside-nav-collapse-dark;
    }

    &__environment-list-item {
      color: $aside-nav-inactive-color-dark;
      path {
        fill: $aside-nav-inactive-color-dark;
      }
      &:hover {
        color: $aside-nav-inactive-hover-color-dark;
        path {
          fill: $aside-nav-inactive-hover-color-dark;
        }
      }
      &.active,
      &.active:hover {
        background-color: $aside-nav-active-dark;
        color: $aside-nav-active-color-dark;
        path {
          fill: $aside-nav-active-color-dark;
        }
      }

      &:hover {
        color: $aside-nav-active-color-dark;
        path {
          fill: $aside-nav-active-color-dark;
        }
      }
    }

    &__footer {
      background-color: $aside-bg-dark;
    }

    .aside {
      &__main-content {
        background-color: $aside-bg-dark;
      }
      .collapsible.active {
        background-color: $aside-nav-collapse-dark;
        box-shadow: inset 4px 0 0 $aside-nav-collapse-border-dark;
      }
    }
  }
}

.aside {
  position: fixed;
  animation-name: asideIn;
  animation-duration: $animation-duration;
  animation-timing-function: $animation-timing-function;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  background-color: $aside-left-bg;

  .collapsible {
    font-weight: 500;
    &-project {
      line-height: $h5-line-height;
      font-size: $line-height-sm;
      .collapsible__header {
        font-weight: bold;
        margin: 20px 0px 8px;
      }
    }
    &__content {
      position: relative;
    }
    &__header {
      color: $text-icon-light;
      svg {
        path {
          fill: $text-icon-light;
        }
      }
    }

    &.active {
      &.collapsible-project {
        .collapsible__header {
          color: $text-icon-light;
          svg {
            path {
              fill: $text-icon-light;
            }
          }
        }
      }
      .collapsible__header {
        color: $primary400;
        svg {
          path {
            fill: $primary400;
          }
        }
      }
      &__header {
        color: $aside-nav-color;
        svg {
          path {
            fill: $aside-nav-color;
          }
        }
      }
    }

    .project-select {
      background-color: $bg-dark300;
      border-radius: $border-radius-lg;
      font-size: $font-size-base;
      line-height: $line-height-base;
      position: absolute;
      top: -40px;
      &-header {
        padding: 8px 12px;
        background-color: $bg-dark200;
        border-top-left-radius: $border-radius-lg;
        border-top-right-radius: $border-radius-lg;
        input {
          background-color: $bg-dark300;
          color: $text-icon-light;
        }
      }
      &-body {
        max-height: 240px;
        overflow-x: auto;
        @include customScroll();
      }
      &-btn {
        padding: 9px 16px;
        color: $text-icon-light;
        &:hover {
          background-color: $bg-dark200;
        }
        &.active {
          background-color: $primary-alfa-16;
        }
      }
    }
  }
  &__environments-wrapper {
    .collapsible__header {
      padding: 8px 0px;
      &:hover {
        color: $aside-nav-hover;
        svg {
          path {
            fill: $aside-nav-hover;
          }
        }
      }
    }
  }

  &__wrapper {
    height: 100%;
  }

  &__main-content {
    position: relative;
    height: 100vh;
    overflow-y: auto !important;
    background-color: $aside-bg;
    width: $aside-width;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    @include customScroll();

    &.disabled {
      .collapsible__header {
        color: $text-icon-grey !important;
        pointer-events: none;
        path {
          fill: $text-icon-grey !important;
        }
      }
      .aside__nav-item,
      .aside__environment-list-item,
      .aside__title-wrapper,
      .aside__link-text {
        color: $white-alpha-32 !important;
        pointer-events: none;
        path {
          fill: $white-alpha-32 !important;
        }
      }
    }
  }

  .nav-logo {
    padding: 21px 0px 21px 32px;
  }

  &__nav-item {
    text-decoration: none;
    color: $aside-nav-color;
    font-size: $font-size-base;
    line-height: $line-height-base;
    svg {
      vertical-align: bottom;
    }
    &.disabled {
      color: $text-icon-grey !important;
      pointer-events: none;
      path {
        fill: $text-icon-grey !important;
      }
      &.active,
      &.active:hover {
        color: $aside-nav-active-color;
      }
    }
    path {
      fill: $aside-nav-color;
    }

    padding: 8px 0 8px 16px;
    border-radius: $border-radius-lg;
    cursor: pointer;
    display: flex;
    align-items: center;
    &.active,
    &.active:hover {
      background-color: $aside-nav-active;
      color: $aside-nav-active-color;
      text-decoration: none;
      path {
        fill: $aside-nav-active-color !important;
      }
    }

    &:hover {
      text-decoration: none;
      color: $aside-nav-hover;
      path {
        fill: $aside-nav-hover;
      }
    }
    &--icon {
      width: 15px;
      margin-right: 0.75em;
    }
  }
  &__title-wrapper {
    text-decoration: none;
    padding: 8px 0px 8px 1rem;
    &:hover {
      .aside__link-text {
        text-decoration: none;
      }
    }
    svg {
      vertical-align: bottom;
    }
  }
  .aside__header-link {
    display: block;
    text-decoration: none;
    &.active,
    &.active:hover {
      .aside__title-wrapper {
        background-color: $aside-nav-active;
        color: $text-icon-light;
        border-radius: $border-radius-lg;
        .aside__link-text {
          color: $text-icon-light;
        }
        .aside__link-icon {
          display: none;
        }
        text-decoration: none !important;
      }
    }
    &:focus {
      text-decoration: none !important;
    }
  }

  &__link-text {
    color: $success;
    font-family: $font-family-header;
    font-weight: 500;
  }

  &__project-title {
    color: $aside-nav-color;
    font-size: 24px;
    line-height: $h4-line-height;
  }

  &__nav-item-icon {
    margin-right: 0.75em;
    width: 15px;
  }

  &__environment-nav {
  }
  &__environment-list-item {
    position: relative;
    display: block;
    color: $aside-nav-color;
    svg {
      vertical-align: bottom;
    }
    &:hover {
      color: $aside-nav-inactive-hover-color;
    }
    padding: 8px 16px;
    margin: 0 32px;
    cursor: pointer;
    &.active,
    &.active:hover {
      border-radius: $border-radius;
      background-color: $aside-nav-active;
      color: $aside-nav-active-color;
      opacity: 1;
      path {
        fill: $text-icon-light;
      }
    }

    &:hover {
      color: $text-icon-light;
      background-color: $primary-alfa-8;
      border-radius: $border-radius;
      opacity: 1;
      text-decoration: none;
      path {
        fill: $text-icon-light;
      }
    }
    &:focus {
      text-decoration: none;
    }

    &--icon {
      width: 15px;
      margin-right: 0.75em;
    }
  }

  &__popover {
    left: 78px;
  }
  &-body {
    padding-left: $aside-width;
    &-full-width {
      padding-left: 0;
    }
  }
  .toggle {
    //position: absolute;
    //top: 15px;
    position: absolute;
    right: 40px;
    top: 18px;
    z-index: 9999999;

    > .icon {
      color: white;
      font-size: 1.5rem;
    }
  }

  &__logo {
    path {
      fill: $aside-logo;
    }
  }
  .align-self-end {
    align-self: auto !important;
  }
}

@include media-breakpoint-down(sm) {
  .aside {
    &__projects-sidebar {
      position: relative;
      top: 3em;
    }
  }
}

@media (max-height: 576px) {
  .aside {
    &__environments-wrapper {
      height: 300px;
    }
  }
}
